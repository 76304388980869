/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "stores/store.context";
import { useDebounce } from "use-debounce";
import { observer } from "mobx-react-lite";

/* Components */
import AdStatus from "./TopbarSearch/AdStatus";
import AdType from "./TopbarSearch/AdType";
import Location from "./TopbarSearch/Location";
import Schedule from "./TopbarSearch/Schedule";
import TextInput from "./TopbarSearch/TextInput";

function TopbarSearchBar({ searchField, clearSearchBar, setClearSearchBar }) {
  const { AdsStore, UserStore } = useContext(StoreContext);
  const { storeGetAds, storeGetAdLogs, setAdsExport } = AdsStore;
  const { profile } = UserStore;
  const { role_key, location_key } = profile;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const [debouncedParams] = useDebounce(searchParams, 1000);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSearchParams({
      filterKey: value.length > 0 ? name : "ad_id",
      filterValue: value,
    });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    
    setSearchParams({
      startDate: e.target.value,
    });
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);

    setSearchParams({
      startDate: startDate,
      endDate: e.target.value,
    });
  };

  useEffect(() => {
    if (clearSearchBar) {
      setStartDate("");
      setEndDate("");
      setSearchParams({});
      setClearSearchBar(false);
      
      if (window.location.pathname === "/") {
        storeGetAds({}, role_key, location_key);
      }
      if (window.location.pathname === "/ad-logs") {
        storeGetAdLogs({}, role_key, location_key);
      }
    }
  }, [clearSearchBar, setClearSearchBar]);

  useEffect(() => {
    const clearState = () => {
      if (searchParams.filterValue !== "") {
        setStartDate("");
        setEndDate("");
        setSearchParams({});
      }
    };

    clearState();
  }, [searchField]);

  useEffect(() => {
    if (debouncedParams.filterKey || debouncedParams.startDate) {
      if (window.location.pathname === "/") {
        storeGetAds(debouncedParams, role_key, location_key);
      }
      if (window.location.pathname === "/ad-logs") {
        storeGetAdLogs(debouncedParams, role_key, location_key);
      }
    }

    setAdsExport(debouncedParams);
  }, [debouncedParams]);

  const props = {
    searchParams: searchParams,
    handleChange: handleChange,
  };

  switch (searchField) {
    case "client":
      return <TextInput name="client" {...props} />;
    case "adType":
      return <AdType name="ad_type_id" {...props} />;
    case "adStatus":
      return <AdStatus name="status" {...props} />;
    case "location":
      return <Location name="location_id" {...props} />;
    case "schedule":
      return (
        <Schedule
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
      );
    default:
      return (
        <p>
          Something went wrong! Search field: <strong>{searchField}</strong> is
          invalid.
        </p>
      );
  }
}

export default observer(TopbarSearchBar);
