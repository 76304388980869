import React, { useState, useEffect, useContext, useCallback } from "react";
import { Outlet } from "react-router-dom";
import Spinner from "components/common/spinner";
import { getAd, getLocations } from "services/AdsApi";
import { StoreContext } from "stores/store.context";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";

function PrivateRoute() {
  const { UserStore, AdsStore } = useContext(StoreContext);
  const { profile, setProfile } = UserStore;
  const { role_key, location_key } = profile;
  const { setUserLocations } = AdsStore;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      await Auth.currentUserInfo().then((data) => {
        if (!data) window.location = "/login";

        setProfile({
          id: data?.attributes.sub,
          email: data?.attributes.email,
          first_name: data?.attributes["custom:first_name"],
          last_name: data?.attributes["custom:last_name"],
          name: data?.attributes["custom:Name"],
          company_name: data?.attributes["custom:company_name"],
          phone_number: data?.attributes["custom:phone_number"],
          profile_picture: data?.attributes["custom:profile_picture"],
          signature: data?.attributes["custom:signature"],
          role_key: data?.attributes["custom:role"],
          location_key: data?.attributes["custom:location"],
        });
      });
    } catch (err) {
      // DO NOTHING
    }
  }, [setProfile]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (role_key) {
      getAd(0, role_key, location_key).then((data) => {
        if (data.message !== "Unauthorized") {
          setIsLoggedIn(true);
        }
      });

      getLocations(role_key, location_key).then((data) =>
        setUserLocations(data)
      );
    }
  }, [setIsLoggedIn, setUserLocations, role_key, location_key]);

  return <>{isLoggedIn ? <Outlet /> : <Spinner />}</>;
}

export default observer(PrivateRoute);
