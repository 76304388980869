import { getRoleKey } from "helpers/Role.helper";
import React, { useContext, useState } from "react";
import { StoreContext } from "stores/store.context";

import TopbarSearchBar from "./TopbarSearchBar";

function TopbarDropdownMenu() {
  const [searchField, setSearchField] = useState("client");
  const [clearSearchBar, setClearSearchBar]= useState(false);
  const { UserStore } = useContext(StoreContext);
  const { profile } = UserStore;

  const roleKey = getRoleKey(profile.role_key);

  const renderLocationSearchOption = () => {
    if (
      roleKey !== process.env.REACT_APP_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    ) return null;

    return <option value="location">Location</option>;
  }

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "clear")
    {
      setClearSearchBar(true);
      setSearchField("client");
    }else setSearchField(selectedValue);
  };

  return (
    <div className="dropdown-container">
      <select
        className="dropdown-field"
        value={searchField}
        onChange={handleDropdownChange}
      >
        <option value="client">Client</option>
        <option value="adType">Ad Type</option>
        <option value="schedule">Schedule</option>
        <option value="adStatus">Ad Status</option>
        {renderLocationSearchOption()}
        <option value="clear">Clear Search </option>
      </select>
      <TopbarSearchBar searchField={searchField} clearSearchBar={clearSearchBar} setClearSearchBar={setClearSearchBar}/>
    </div>
  );
}

export default TopbarDropdownMenu;
