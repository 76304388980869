import { Button, Label, TextField } from "components/common/forms";
import { formWrapperStyle } from "components/common/forms/FormWrapperStyle";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { handleFormChange } from "helpers/Form.helper";
import { getLocations } from "services/AdsApi";
import { StoreContext } from "stores/store.context";
import { HiOutlineUpload } from "react-icons/hi";
import {
  createUser,
  uploadPhoto,
  uploadUserSignaturePhoto,
} from "services/UserApi";
import { getRoleKey } from "helpers/Role.helper";
import { useNavigate, useParams } from "react-router";
import { MdErrorOutline } from "react-icons/md";
import PropTypes from "prop-types";
import { getUser, updateUser } from "helpers/AdminCard.helper";
import Swal from "sweetalert2";

export const photoUploadStyle = css`
  background: ${(props) => props.theme.colors.secondary};
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem 0;
  max-width: 25rem;
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    background-color: ${(props) => props.theme.colors.secondaryActive};
  }

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.form`
  ${formWrapperStyle};

  .photo-upload-field {
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .profile-photo-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 25rem;

    .profile-photo {
      margin: 0 auto;
      object-fit: contain;
      width: 15rem;
      height: 15rem;
      border: 5px solid #fff;
      outline: 2px solid ${(props) => props.theme.colors.secondary};
      border-radius: 50%;
    }
  }

  .profile-photo-upload {
    ${photoUploadStyle};
  }

  .error-message {
    display: flex;
    align-items: center;
    color: red;
    gap: 0.25rem;
    margin-left: auto;
  }

  .btn-container {
    width: 15rem;
    margin-left: auto;
  }
`;

const NATIONAL_USER_ROLES = [
  {
    name: "Master Admin",
    value: "master_admin",
  },
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Local Master Admin",
    value: "local_master_admin",
  },
  {
    name: "Local Admin",
    value: "local_admin",
  },
];

const LOCAL_USER_ROLES = [
  {
    name: "Local Master Admin",
    value: "local_master_admin",
  },
  {
    name: "Local Admin",
    value: "local_admin",
  },
];

function UserForm({ editMode }) {
  const navigate = useNavigate();

  const { id } = useParams();

  const { UiStore, UserStore } = useContext(StoreContext);
  const { role_key, location_key, id: userId } = UserStore?.profile;
  const {
    setLocalAdmins,
    setNationalAdmins,
    setIsGlobalModalOpen,
    setModalContent,
  } = UiStore;

  const emptyForm = {
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    location: location_key || "",
    phoneNumber: "",
    password: "",
  };
  const [formDetails, setFormDetails] = useState(emptyForm);
  const [selectedProfilePhoto, setSelectedProfilePhoto] = useState("");
  const [selectedProfilePhotoSrc, setSelectedProfilePhotoSrc] = useState(null);
  const [selectedSignaturePhoto, setSelectedSignaturePhoto] = useState("");
  const [selectedSignaturePhotoSrc, setSelectedSignaturePhotoSrc] =
    useState(null);
  const [locations, setLocations] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (editMode) {
      getUser(id)
        .then((res) => {
          const { UserAttributes } = res;
          const email = UserAttributes.find(
            (att) => att.Name === "email"
          )?.Value;
          const first_name = UserAttributes.find(
            (att) => att.Name === "custom:first_name"
          )?.Value;
          const last_name = UserAttributes.find(
            (att) => att.Name === "custom:last_name"
          )?.Value;
          const phoneNumber = UserAttributes.find(
            (att) => att.Name === "custom:phone_number"
          )?.Value;
          const role = UserAttributes.find(
            (att) => att.Name === "custom:role"
          )?.Value;
          const location = UserAttributes.find(
            (att) => att.Name === "custom:location"
          )?.Value;
          const profilePicture = UserAttributes.find(
            (att) => att.Name === "custom:profile_picture"
          )?.Value;
          const signaturePhoto = UserAttributes.find(
            (att) => att.Name === "custom:signature"
          )?.Value;

          setFormDetails({
            firstName: first_name || "",
            lastName: last_name || "",
            role: role || "",
            email: email || "",
            location: location || "",
            phoneNumber: phoneNumber || "",
            password: "",
            profilePicture:
              profilePicture || process.env.REACT_APP_DEFAULT_PROFILE_PHOTO,
            signature:
              signaturePhoto || process.env.REACT_APP_DEFAULT_SIGNATURE_PHOTO,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [editMode, id]);

  const uploadProfilePhoto = useCallback(async () => {
    const formData = new FormData();
    formData.append("image", selectedProfilePhoto);

    const res = await uploadPhoto(formData, role_key, location_key);

    return res.url;
  }, [location_key, role_key, selectedProfilePhoto]);

  const uploadSignaturePhoto = useCallback(async () => {
    const formData = new FormData();
    formData.append("image", selectedSignaturePhoto);

    const res = await uploadUserSignaturePhoto(
      formData,
      role_key,
      location_key
    );

    return res.url;
  }, [location_key, role_key, selectedSignaturePhoto]);

  const handleCreateUser = useCallback(async () => {
    window.scrollTo(0, 0);
    setIsGlobalModalOpen(true);
    setModalContent("formSubmission");

    const profilePhotoUrl = await uploadProfilePhoto();
    const signaturePhotoUrl = await uploadSignaturePhoto();

    const params = {
      ...formDetails,
      profilePicture:
        profilePhotoUrl || process.env.REACT_APP_DEFAULT_PROFILE_PHOTO,
      signature:
        signaturePhotoUrl || process.env.REACT_APP_DEFAULT_SIGNATURE_PHOTO,
      addedById: userId,
    };

    const res = await createUser(params, role_key, location_key);
    const { statusCode, User, message } = res;

    if (statusCode === 400) {
      setIsGlobalModalOpen(false);
      setError(message);
      Swal.fire("Error:", message, "error");
    }

    if (User) {
      const { Attributes } = User;
      const userRole = Attributes.find(
        (att) => att.Name === "custom:role"
      )?.Value;

      if (NATIONAL_USER_ROLES.find((role) => role.value === userRole)) {
        setNationalAdmins();
      }

      if (LOCAL_USER_ROLES.find((role) => role.value === userRole)) {
        setLocalAdmins();
      }

      navigate("/accounts");
    }

    setIsGlobalModalOpen(false);
  }, [
    setIsGlobalModalOpen,
    setModalContent,
    uploadProfilePhoto,
    uploadSignaturePhoto,
    formDetails,
    userId,
    role_key,
    location_key,
    navigate,
    setNationalAdmins,
    setLocalAdmins,
  ]);

  const handleUpdateUser = useCallback(async () => {
    window.scrollTo(0, 0);
    setIsGlobalModalOpen(true);
    setModalContent("formSubmission");

    let params = formDetails;

    if (selectedProfilePhoto) {
      const profilePhotoUrl = await uploadProfilePhoto();
      params = { ...params, profilePicture: profilePhotoUrl };
    }

    if (selectedSignaturePhoto) {
      const signaturePhotoUrl = await uploadSignaturePhoto();
      params = { ...params, signature: signaturePhotoUrl };
    }

    try {
      await updateUser(id, params);

      if (NATIONAL_USER_ROLES.find((role) => role.value === formDetails.role)) {
        setNationalAdmins();
      }

      if (LOCAL_USER_ROLES.find((role) => role.value === formDetails.role)) {
        setLocalAdmins();
      }

      navigate("/accounts");
    } catch (error) {
      setError(error);
      Swal.fire("Error:", error, "error");
    } finally {
      setIsGlobalModalOpen(false);
    }
  }, [
    setIsGlobalModalOpen,
    setModalContent,
    formDetails,
    selectedProfilePhoto,
    selectedSignaturePhoto,
    uploadProfilePhoto,
    uploadSignaturePhoto,
    id,
    navigate,
    setNationalAdmins,
    setLocalAdmins,
  ]);

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await getLocations(role_key, location_key);
      setLocations(data);
    };

    if (role_key) {
      fetchLocations();
    }

    return () => null;
  }, [role_key, location_key]);

  const locationOptions = useCallback(() => {
    return locations.map((loc) => (
      <option key={loc.location_id} value={loc.location_id}>
        {loc.name}
      </option>
    ));
  }, [locations]);

  const renderLocations = useCallback(() => {
    if (getRoleKey(role_key) !== process.env.REACT_APP_MASTER_ADMIN_KEY)
      return null;

    if (formDetails.role === "master_admin" || formDetails.role === "admin")
      return null;

    return (
      <div className="adform-field">
        <Label label="Station" sublabel="Select a station" htmlFor="location" />
        <select
          className="dropdown-field"
          name="location"
          onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
          defaultValue=""
        >
          <option value="" disabled hidden>
            Select a location
          </option>
          {locationOptions()}
        </select>
      </div>
    );
  }, [formDetails, locationOptions, role_key]);

  const roles = useMemo(
    () =>
      getRoleKey(role_key) === process.env.REACT_APP_MASTER_ADMIN_KEY
        ? NATIONAL_USER_ROLES
        : LOCAL_USER_ROLES,
    [role_key]
  );

  const roleOptions = useCallback(() => {
    return roles.map((role) => (
      <option key={role.name} value={role.value}>
        {role.name}
      </option>
    ));
  }, [roles]);

  const disableSubmit = useCallback(() => {
    for (const field in formDetails) {
      if (editMode && field === "password") {
        continue;
      }

      if (
        field === "location" &&
        (formDetails.role === "master_admin" || formDetails.role === "admin")
      ) {
        continue;
      }

      if (field === "profilePicture" && selectedProfilePhotoSrc) {
        continue;
      }

      if (field === "signature" && selectedSignaturePhotoSrc) {
        continue;
      }

      if (formDetails[field]?.length < 1) return true;
    }

    if (formDetails.phoneNumber.length !== 11) return true;

    if (!editMode && formDetails.password !== confirmPassword) return true;

    return false;
  }, [
    editMode,
    formDetails,
    confirmPassword,
    selectedProfilePhotoSrc,
    selectedSignaturePhotoSrc,
  ]);

  const renderError = useCallback(() => {
    if (!error) return null;

    return (
      <p className="error-message">
        <MdErrorOutline /> {error}
      </p>
    );
  }, [error]);

  const renderPasswordFields = () => {
    if (editMode) return null;

    return (
      <div className="form-section">
        <p className="form-section-title">Password</p>
        <div className="adform-field">
          <Label
            label="Temporary Password"
            sublabel="Type in user's temporary password"
            htmlFor="password"
          />
          <TextField
            type="password"
            name="password"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.password}
          />
        </div>
        <div className="adform-field">
          <Label
            label="Confirm Password"
            sublabel="Confirm user's temporary password"
            htmlFor="confirmPassword"
          />
          <TextField
            type="password"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </div>
        <hr className="hr" />
      </div>
    );
  };

  const renderButton = () => {
    if (editMode) {
      return (
        <Button
          title="UPDATE"
          type="button"
          onClick={handleUpdateUser}
          isDisabled={disableSubmit()}
        />
      );
    }

    return (
      <Button
        title="CREATE"
        type="button"
        onClick={handleCreateUser}
        isDisabled={disableSubmit()}
      />
    );
  };

  const handleSelectProfilePhoto = (ev) => {
    const photo = ev.target.files[0];

    setSelectedProfilePhoto(photo);

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedProfilePhotoSrc(reader.result);
    };
    reader.readAsDataURL(photo);
  };

  const handleSelectSignaturePhoto = (ev) => {
    const photo = ev.target.files[0];

    setSelectedSignaturePhoto(photo);

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedSignaturePhotoSrc(reader.result);
    };
    reader.readAsDataURL(photo);
  };

  return (
    <Wrapper>
      <div className="form-section">
        <p className="form-section-title">Personal Information</p>
        <div className="adform-field">
          <Label
            label="First Name"
            sublabel="Type in user's first name"
            htmlFor="firstName"
          />
          <TextField
            type="text"
            name="firstName"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.firstName}
          />
        </div>
        <div className="adform-field">
          <Label
            label="Last Name"
            sublabel="Type in user's last name"
            htmlFor="lastName"
          />
          <TextField
            type="text"
            name="lastName"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.lastName}
          />
        </div>
        <hr className="hr" />
      </div>

      <div className="form-section">
        <p className="form-section-title">Station Information</p>
        <div className="adform-field">
          <Label label="Role" sublabel="Select a role" htmlFor="role" />
          <select
            className="dropdown-field"
            name="role"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            defaultValue={formDetails.role}
            value={formDetails.role}
          >
            <option value="" disabled hidden>
              Select a role
            </option>
            {roleOptions()}
          </select>
        </div>
        {renderLocations()}
        <hr className="hr" />
      </div>

      <div className="form-section">
        <p className="form-section-title">Contact Information</p>
        <div className="adform-field photo-upload-field">
          <Label
            label="Profile Photo"
            sublabel="Upload user's profile photo"
            htmlFor="profilePhoto"
          />
          <div className="profile-photo-container">
            {(selectedProfilePhotoSrc || formDetails.profilePicture) && (
              <img
                src={selectedProfilePhotoSrc ?? formDetails.profilePicture}
                alt="profile-pic"
                className="profile-photo"
              />
            )}
            <label className="profile-photo-upload">
              {selectedProfilePhoto ? (
                `File: ${selectedProfilePhoto.name}`
              ) : (
                <>
                  <HiOutlineUpload
                    style={{ marginRight: "0.5rem", fontSize: "1.25rem" }}
                  />{" "}
                  Upload
                </>
              )}
              <input
                type="file"
                name="profilePhoto"
                accept="image/jpg, image/png, image/jpeg"
                variant="contained"
                style={{ display: "none" }}
                onChange={handleSelectProfilePhoto}
              />
            </label>
          </div>
        </div>
        <div className="adform-field photo-upload-field">
          <Label
            label="Signature Photo"
            sublabel="Upload user's signature photo"
            htmlFor="signaturePhoto"
          />
          <div className="profile-photo-container">
            {(selectedSignaturePhotoSrc || formDetails.signature) && (
              <img
                src={selectedSignaturePhotoSrc ?? formDetails.signature}
                alt="profile-pic"
                className="profile-photo"
              />
            )}
            <label className="profile-photo-upload">
              {selectedSignaturePhoto ? (
                `File: ${selectedSignaturePhoto.name}`
              ) : (
                <>
                  <HiOutlineUpload
                    style={{ marginRight: "0.5rem", fontSize: "1.25rem" }}
                  />{" "}
                  Upload
                </>
              )}
              <input
                type="file"
                name="profilePhoto"
                accept="image/jpg, image/png, image/jpeg"
                variant="contained"
                style={{ display: "none" }}
                onChange={handleSelectSignaturePhoto}
              />
            </label>
          </div>
        </div>
        <div className="adform-field">
          <Label
            label="Email Address"
            sublabel="Type in user's email address"
            htmlFor="email"
          />
          <TextField
            type="email"
            name="email"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.email}
          />
        </div>
        <div className="adform-field">
          <Label
            label="Contact Number"
            sublabel="Type in user's contact number"
            htmlFor="phoneNumber"
          />
          <TextField
            type="text"
            name="phoneNumber"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.phoneNumber}
          />
        </div>
        <hr className="hr" />
      </div>
      {renderPasswordFields()}

      {renderError()}

      <div className="btn-container">{renderButton()}</div>
    </Wrapper>
  );
}

UserForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
};

export default UserForm;
