import { lazy } from "react";

const FormSubmission = lazy(() => import("./FormSubmission"));
const GenerateAdReport = lazy(() => import("./GenerateAdReport"));
const SyncComplete = lazy(() => import("./SyncComplete"));
const SyncNotif = lazy(() => import("./SyncNotif"));

const modalContents = {
  formSubmission: FormSubmission,
  generateAdReport: GenerateAdReport,
  syncComplete: SyncComplete,
  syncNotif: SyncNotif,
};

export default modalContents;
