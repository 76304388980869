import axios from "axios";
import Swal from 'sweetalert2';
import { getRoleKey } from "helpers/Role.helper";
import { getRoleLocationFilter } from "./services.utils";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const USER_TOKEN = localStorage.getItem("token");

const handleError = (e) => {
  console.error(e);
};

const handleDuplicateError = (e)=>{
  Swal.fire({
    icon: 'error',
    title: 'Ref Code is already in use!',
    html: `Ad cannot be saved due to duplications in your ASC code. <br> Please try again with a unique code.`,
  });
}

const getLocations = async (role, location) => {
  const searchFilters = getRoleLocationFilter(role, location);

  let queryUrl =
    "/dashboard/location/?" + new URLSearchParams(searchFilters).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    handleError(e);
  }
};

const getAdTypes = async (role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl = "/misc/ad_type/?" + new URLSearchParams(params).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    handleError(e);
  }
};

const getAd = async (id, role, location) => {
  const searchFilters = getRoleLocationFilter(role, location);
  searchFilters.filterKey = "ad_id";
  searchFilters.filterValue = id;

  let queryUrl =
    "/dashboard/ads?" + new URLSearchParams(searchFilters).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    handleError(e);
  }
};

const getAdLogs = async (filters, role, location) => {
  const searchFilters = {
    orderBy: "ad_id",
    order: "desc",
    offset: 0,
    limit: 1,
    ...filters,
    ...getRoleLocationFilter(role, location),
  };

  let queryUrl =
    "/dashboard/match/?" + new URLSearchParams(searchFilters).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    handleError(e);
  }
};

const getAds = async (filters, role, location) => {
  const searchFilters = {
    orderBy: "ad_id",
    order: "desc",
    offset: 0,
    limit: 10,
    ...filters,
    ...getRoleLocationFilter(role, location),
  };

  const queryUrl =
    "/dashboard/ads/?" + new URLSearchParams(searchFilters).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    handleError(e);
  }
};

const editAd = async (id, formDetails, role, location) => {
  const params = {
    role: getRoleKey(role),
  };

  if (location) {
    formDetails.locations = [location];
    params.location = location;
  }

  let queryUrl = "/dashboard/ads/" + id + "?" + new URLSearchParams(params).toString();

  const controller = new AbortController();
  const signal = controller.signal;

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDetails),
      signal,
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    const result = await response.json();

    if (result.success) {
      console.log(result.success);
    } else {
      handleError(result.error);
      handleDuplicateError(result.error);
    }
  } catch (error) {
    handleError(error);
  } finally {
    controller.abort();
  }
};

const postAd = async (formDetails, role, location) => {
  const params = {
    role: getRoleKey(role),
  };

  if (location) {
    formDetails.locations = [location];
    params.location = location;
  }

  let queryUrl = "/dashboard/ads/?" + new URLSearchParams(params).toString();

  const controller = new AbortController();
  const signal = controller.signal;

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDetails),
      signal,
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    const result = await response.json();

    if (result.success) {
      console.log(result.success);
    } else {
      handleError(result.error);
      handleDuplicateError(result.error);
    }
  } catch (error) {
    handleError(error);
  } finally {
    controller.abort();
  }
};

const deleteAd = async (id, role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl =
    "/dashboard/ads/" + id + "?" + new URLSearchParams(params).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

const exportAds = async (query, role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl =
    `/dashboard/export/ads/?${query}` + new URLSearchParams(params).toString();

  try {
    const response = await axios.get(BASE_URL + queryUrl, {
      headers: {
        Authorization: `Bearer ${USER_TOKEN}`,
      },
    });
    const url = response.data.fileUrl;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ads-export.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    if (response.status === 401) {
      window.location = "/login";
    }
  } catch (err) {
    console.log(err, "ads export error");
  }
};

const exportLogs = async (query, role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl =
    `/dashboard/export/logs/?${query}` + new URLSearchParams(params).toString();

  try {
    const response = await axios.get(BASE_URL + queryUrl, {
      headers: {
        Authorization: `Bearer ${USER_TOKEN}`,
      },
    });
    const url = response.data.fileUrl;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "logs-export.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    if (response.status === 401) {
      window.location = "/login";
    }
  } catch (err) {
    console.log(err, "ads export error");
  }
};

const getOfficers = async (role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl = `/misc/officer/?` + new URLSearchParams(params).toString();

  try {
    const response = await axios.get(BASE_URL + queryUrl, {
      headers: {
        Authorization: `Bearer ${USER_TOKEN}`,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return response.data;
  } catch (err) {
    /* do nothing */
  }
};

const getLogsTableData = async (query, role, location) => {
  const params = {
    ...query,
    ...getRoleLocationFilter(role, location),
  };

  let queryUrl = `/export/logs?` + new URLSearchParams(params).toString();

  try {
    const response = await axios.get(BASE_URL + queryUrl, {
      headers: {
        Authorization: `Bearer ${USER_TOKEN}`,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return response.data;
  } catch (err) {
    /* do nothing */
  }
};

const getCertificateOfPerformance = async (
  query,
  body,
  role,
  location,
  isPreview = false
) => {
  const params = {
    isPreview,
    ...query,
    ...getRoleLocationFilter(role, location),
  };

  let queryUrl = `/export/cert-full?` + new URLSearchParams(params).toString();

  const controller = new AbortController();
  const signal = controller.signal;

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
		signal,
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (err) {
    /* do nothing */
  }finally {
      controller.abort();
  }
};

const getImplementationReport = async (
  query,
  body,
  role,
  location,
  isPreview = false
) => {
  const params = {
    isPreview,
    ...query,
    ...getRoleLocationFilter(role, location),
  };

  delete params.startDate;
  delete params.endDate;

  let queryUrl = `/export/cert-impl?` + new URLSearchParams(params).toString();

  const controller = new AbortController();
  const signal = controller.signal;
  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${USER_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      signal
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (err) {
    /* do nothing */
  } finally {
    controller.abort();
  }
};

export {
  deleteAd,
  editAd,
  exportAds,
  exportLogs,
  getAd,
  getAdTypes,
  getAds,
  getAdLogs,
  getCertificateOfPerformance,
  getImplementationReport,
  getLocations,
  getLogsTableData,
  getOfficers,
  postAd,
};
