/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "./AdForm.styled";
import { useParams } from "react-router";
import { deleteAd, editAd, getAd, getAdTypes, postAd } from "services/AdsApi";
import { handleFormChange } from "helpers/Form.helper";
import { getRoleKey } from "helpers/Role.helper";
import { StoreContext } from "stores/store.context";
import moment from "moment";

/* Components */
import {
  Button,
  Checkbox,
  DatePicker,
  Label,
  TextField,
} from "components/common/forms";
import AdTypeForm from "./AdTypeForm";

function AdForm({ editMode, duplicate }) {
  const navigate = useNavigate();
 
  const { id } = useParams();

  const { UserStore, AdsStore } = useContext(StoreContext);

  const { profile } = UserStore;
  const roleKey = profile.role_key;
  const location = profile.location_key;

  const { locations } = AdsStore;

  const emptyForm = {
    client: "",
    product_name: "",
    start_date: "",
    end_date: "",
    locations: [],
    types: [],
  };
  const [formDetails, setFormDetails] = useState(emptyForm);
  const [adTypes, setAdTypes] = useState([]);

  useEffect(() => {
    getAdTypes(roleKey, location).then(data => setAdTypes(data));
  }, [])

  useEffect(() => {
    if (editMode || duplicate) {
      getAd(id, roleKey, location).then((data) => {
        const { ads } = data;
        const ad = ads[0];
        setFormDetails({
          client: ad.client,
          product_name: ad.product_name,
          start_date: moment(ad.start_date).format("YYYY-MM-DD"),
          end_date: moment(ad.end_date).format("YYYY-MM-DD"),
          locations: ad.locations.map((loc) => loc.id.toString()),
          types: ad.types,
        });
      });
    }
  }, []);

  const handleAdTypeChange = (prop, name, value) => {
    const newFormTypes = formDetails.types;

    const ad = newFormTypes.find((type) => type.name === name);
    ad[prop] = value;

    setFormDetails({ ...formDetails, types: newFormTypes });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    await editAd(id, formDetails, roleKey, location).then((r) => navigate("/"));
    setFormDetails(emptyForm);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    await deleteAd(id, roleKey, location).then((r) => navigate("/"));
    setFormDetails(emptyForm);
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    await postAd(formDetails, roleKey, location).then((r) => navigate("/"));
    setFormDetails(emptyForm);
  };

  if (
    getRoleKey(roleKey) !== process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY &&
    getRoleKey(roleKey) !== process.env.REACT_APP_MASTER_ADMIN_KEY
  )
    navigate("/");

  const renderLocationsCheckbox = () => {
    if (getRoleKey(roleKey) !== process.env.REACT_APP_MASTER_ADMIN_KEY)
      return null;

    return (
      <div className="adform-field">
        <Label label="Locations" sublabel="Check all that applies" />
        <div className="checkbox-container">
          {locations?.map((loc) => {
            return (
              <Checkbox
                key={loc.location_id}
                name="locations"
                id={loc.location_id}
                isChecked={formDetails.locations.includes(
                  loc.location_id.toString()
                )}
                onChange={(e) =>
                  handleFormChange(e, formDetails, setFormDetails)
                }
                value={loc.location_id}
                label={loc.name}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const formButtons = () => {
    if (editMode) {
      return (
        <div className="update-btn-container">
          <Button
            title="UPDATE"
            type="button"
            onClick={(e) => handleUpdate(e)}
          />
          <Button
            title="DELETE"
            type="button"
            onClick={(e) => handleDelete(e)}
          />
        </div>
      );
    }

    return (
      <div className="publish-btn">
        <Button
          title="PUBLISH"
          type="button"
          onClick={(e) => handlePublish(e)}
        />
      </div>
    );
  };

  return (
    <Wrapper>
      <div className="adform-field">
        <Label
          label="Client"
          sublabel="Name of the Company"
          htmlFor="client"
        />
        <TextField
          type="text"
          name="client"
          onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
          value={formDetails.client}
        />
      </div>
      <div className="adform-field">
        <Label
          label="Start Date"
          sublabel="Advertisement Start Date"
          htmlFor="start_date"
        />
        <DatePicker
          name="start_date"
          onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
          value={formDetails.start_date}
        />
      </div>
      <div className="adform-field">
        <Label
          label="End Date"
          sublabel="Advertisement End Date"
          htmlFor="end_date"
        />
        <DatePicker
          name="end_date"
          onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
          value={formDetails.end_date}
          min={formDetails.start_date}
        />
      </div>
      {renderLocationsCheckbox()}
      <div className="adform-field">
        <Label
          label="Type of Advertisement"
          sublabel="Check all that applies"
        />
        <div className="checkbox-container">
          {adTypes?.map((adtype) => {
            return (
              <Checkbox
                key={adtype.id}
                name="types"
                id={adtype.id}
                isChecked={formDetails.types
                  .map((type) => type.id)
                  .includes(adtype.id)}
                onChange={(e) =>
                  handleFormChange(e, formDetails, setFormDetails)
                }
                value={JSON.stringify({
                  id: adtype.id,
                  name: adtype.name,
                  product_name: "",
                  ref_code:"",
                  media_value: "",
                  ad_schedules: [],
                })}
                label={adtype.name}
              />
            );
          })}
        </div>
      </div>
      <hr className="hr" />
      {formDetails.types.map((type) => {
        return (
          <AdTypeForm
            key={type.name}
            name={type.name}
            productName={
              formDetails.types.find((adtype) => adtype.name === type.name)
                ?.product_name
            }
            refCode={
              formDetails.types.find((adtype) => adtype.name === type.name)
                ?.ref_code
            }
            schedules={
              formDetails.types.find((adtype) => adtype.name === type.name)
                ?.ad_schedules
            }
            schedulesCount={
              formDetails.types.find((adtype) => adtype.name === type.name)
                ?.ad_schedules.length
            }
            mediaValue={
              formDetails.types.find((adtype) => adtype.name === type.name)
                ?.media_value
            }
            handleChange={handleAdTypeChange}
          />
        );
      })}
      {formButtons()}
    </Wrapper>
  );
}

export default AdForm;
